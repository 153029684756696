import React, { useEffect, useState } from 'react'
import { Column, Container, DisplayFlex, Name, NameContainer, Number, RecognitionColumnHeader, RecognitionHeader, Row, RowWrapper, ScrollableContainer, StyledModalV2, TopRecognitionContainer, TopRecognized } from '../../Recognition/CompanyAnalytics/styles'
import HoverButton from '../HoverButton'
import { DownloadIconNew, TablePopUpCloseIcon } from '../../../utils/icons'
import { isEmpty, isNull, isUndefined } from 'lodash'
import PropTypes from "prop-types";
import { ImageUrl } from '../../../utils/constants';
import CSVList from '../../AttendeesModal/CSVList'
import LazyImage from '../LazyImage/LazyImage'
import YearDropdownPopup from '../YearDropDownPopup/YearDropDownPopup'
// import { ClosePopUpV2 } from '../../Recognition/AddCoreValues/styles'

const Table = (props) => {
  const{ tableData, tableTitle, tableColumnTitle, tableColumnKey, tableRowKey, show, hide,id, selectedYearInfo, yearDataList, onYearSelect, isYearDropDown} = props;
  const[size,setSize]=useState('');
  const[isPrint, setIsPrint]=useState(false);
  const[csvHeaders,setCsvHeaders]=useState([]);
  const[csvData,setCsvData]=useState([]);

  useEffect(()=>{
    let size = !isEmpty(tableData) ? tableData.slice(0, 1).map((department) => department.data.length) : null;

    setSize(size)
  },[]);

  const printAction = (bool) => {
    let departmentsData = []
    let updatedDepartmentCSV = tableData && tableData.length > 0 &&
      tableData.map((departments) => {
        if (departmentsData.length < 1) {
          departmentsData = departments.data.map((item) =>  ({ label: item[tableRowKey], key: item[tableRowKey].toUpperCase() }));
          setCsvHeaders([{label:tableColumnTitle, key:tableColumnTitle.toUpperCase()},...departmentsData])
        }
        let obj = {};
        let objKey = tableColumnTitle.toUpperCase();
        obj[objKey] = departments[tableColumnKey];
        departments.data.map((actualData) => {
          if ((actualData[tableRowKey].toUpperCase())) {
            obj[actualData[tableRowKey].toUpperCase()] = !isUndefined(actualData) ? actualData['count'] : null;
          }
        })
        return obj;
      });

    if(bool){
      setCsvData(updatedDepartmentCSV);
    }else{
      setCsvHeaders([])
      setCsvData([])
    }

    setIsPrint(bool);
    
  };

  useEffect(()=>{},[csvData,csvHeaders,isPrint])

  return (
    <React.Fragment>
      <StyledModalV2 transform={1} show={show} onHide={hide}>
        <TopRecognitionContainer Size={size} style={{}}>
          <TopRecognized display={'flex'} style={{marginTop:'0px', marginBottom:'15px', width:'1246px', maxHeight:'625px', overflowY:'scroll'}} Size={size}>
            <RecognitionHeader style={{alignItems:'center'}}>
              <p>{tableTitle}</p>
              <div style={{display:'flex', gap:'5px'}}>
                {isYearDropDown && <YearDropdownPopup
                  title={selectedYearInfo && <div className="title">{selectedYearInfo}</div>}
                  labelStyle={{color:"#669db7"}}
                  style={{backgroundColor:""}}
                  data={yearDataList}
                  onSelect={onYearSelect}
                  popUpName={id}
                />}
                <HoverButton width={"40px"} height={"40px"}  title={"Download"} fillOpacity={1} disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"} svgPath={<DownloadIconNew/>} 
                  onClick={() =>printAction(true)}/>
                <HoverButton  title={"Close"} svgPath={<TablePopUpCloseIcon/>} 
                  onClick={hide} fillOpacity={1} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"}/>
                {/* <ClosePopUpV2 style={{background:'#e6ecee'}}> <img src='/images/new_close_icon.png' onClick={hide} /></ClosePopUpV2> */}
              </div>
            </RecognitionHeader>
            {!isNull(tableData) && !isUndefined(tableData) && !isEmpty(tableData) && tableData.length > 0 ?
              <div>
                <Container width={'20%'} department={1} padding={size}>
                  <RecognitionColumnHeader width={'100%'} padding={'10px 0px 10px 26px'}>
                    <div>
                      <Column width={'100%'} display={'flex'}>
                        <span>{tableColumnTitle}</span>
                      </Column>
                    </div>

                  </RecognitionColumnHeader>
                  <RowWrapper width={'100%'}>
                    {tableData.map((data, index) => (
                      <Row key={index} display={1} height={'50px'} border={index === 0 ? 'none' : '1px solid rgba(156, 156, 156, 0.4)'}>
                        <NameContainer width={'100%'} display={1}>
                          <Name marginLeft={'10px'} display={1} maxWidth={'230px'} textAlign={1}>{data[tableColumnKey]}</Name>
                          {index === 0 && <span className='emp-value' style={{color:'linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%)'}}>{tableColumnTitle} of the Year</span>}
                        </NameContainer>
                      </Row>
                    ))}
                  </RowWrapper>
                </Container>
                <ScrollableContainer divWidth={size} width={'80%'} padding={size}>
                  <div>
                    <DisplayFlex>
                      {tableData.slice(0, 1).map((department, index) => (
                        <RecognitionColumnHeader width={'100%'} key={index} display={'flex'} style={{padding:"0px", alignItems:'center'}}>
                          {department.data.map((recognition, recogId) => (
                            <Column width={'100%'} key={recogId} widthForImg={recognition[tableRowKey] === "People" ? '30px' : '25px'} style={{background:recognition.background ? recognition.background : recognition["key"] === "total" ? 'rgba(105, 194, 255, 0.1)': (recognition.background && recognition.background),margin:recognition.margin && recognition.margin,height:'45.08px', display:'flex', alignItems:'center', color:recognition && recognition.key && recognition["key"] === "total" && "#0D4270"}}>
                              { recognition["key"] != "total" && <LazyImage src={`${ImageUrl}/${recognition.icon}`} title={recognition[tableRowKey]}/>}
                              { (!recognition.icon || recognition["key"] == "total") && <span style={{fontFamily: recognition["key"] === "total" && "Rubik-medium", opacity: recognition["key"] === "total" && "1", color:'#0D4270'}}>{recognition[tableRowKey]}</span>}
                            </Column>
                          ))}
                        </RecognitionColumnHeader>
                      ))}
                    </DisplayFlex>
                    {tableData.map((department, index) => (
                      <RowWrapper width={'100%'} key={index}  >
                        <Row border={index === 0 ? 'none' : '1px solid rgba(156, 156, 156, 0.4)'} style={{minHeight:"unset", padding:'0px'}}>
                          {department.data.map((recognition, recogId) => (
                            <NameContainer width={'100%'} key={recogId} style={{background:recognition[tableRowKey] === "Total Recognition" ? 'rgba(105, 194, 255, 0.1)':(recognition.background && recognition.background),margin:recognition.margin && recognition.margin, minHeight:'80px', display:'flex', alignItems:'center', width:"198px"}}>
                              <Number>{recognition.count}</Number>
                            </NameContainer>
                          ))}
                        </Row>
                      </RowWrapper>
                    ))}
                  </div>
                </ScrollableContainer>
              </div> : <RowWrapper><Row opacity>No Data Found</Row></RowWrapper>}
          </TopRecognized>
        </TopRecognitionContainer>
      </StyledModalV2>
      {isPrint && csvHeaders.length > 0 && csvData.length > 0 &&   <CSVList Title={tableTitle} removeCSVData={printAction} headers={csvHeaders} data={csvData} />}
    </React.Fragment>
  )
}

Table.propTypes = {
  tableData:PropTypes.array,
  tableTitle:PropTypes.string,
  tableColumnTitle:PropTypes.string,
  tableColumnKey:PropTypes.string,
  tableRowKey:PropTypes.string,
  hide : PropTypes.func,
  show: PropTypes.bool,
  selectedYearInfo: PropTypes.number,
  yearDataList: PropTypes.array,
  onYearSelect: PropTypes.func,
  isYearDropDown : PropTypes.bool,
  id:PropTypes.string
};

export default Table